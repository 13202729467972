<template>
  <div v-if="isAuthenticated" class="relative shrink-0 bg-[#105c8e] w-[70px]">
    <!-- <router-link to="/">
      <li
        class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hidden"
      >
        <img
          src="https://isg-one.com/images/default-source/ResourcePackages/ISG2021/svg/logos/isg-logo_white.svg"
          alt="vetri logo"
          class="w-16 object-contain h-full"
        />
        <span class="cbs-tooltip"> Dashboard </span>
      </li>
    </router-link> -->
    <router-link to="/" data-te-toggle="tooltip">
      <li
        class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
          />
        </svg>
        <span class="cbs-tooltip"> Dashboard </span>
      </li>
    </router-link>
    <!-- 
    <router-link to="/leads" data-te-toggle="tooltip">
      <li
        class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
          />
        </svg>

        <span class="cbs-tooltip"> Leads</span>
      </li>
    </router-link> -->

    <template v-if="this.userInfo.user.role == 'Sales Manager'">
      <router-link to="/sales-team/" data-te-toggle="tooltip">
        <li
          class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
            />
          </svg>
          <span class="cbs-tooltip"> Sales Executive</span>
        </li>
      </router-link>
    </template>

    <template v-if="this.userInfo.user.role == 'admin'">
      <router-link to="/management" data-te-toggle="tooltip">
        <li
          class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
            />
          </svg>
          <span class="cbs-tooltip"> Management</span>
        </li>
      </router-link>
    </template>
    <template v-if="this.userInfo.user.role == 'admin'">
      <router-link to="/deals" data-te-toggle="tooltip">
        <li
          class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>

          <span class="cbs-tooltip"> Deals Room</span>
        </li>
      </router-link>
    </template>

    <router-link to="/payments" data-te-toggle="tooltip">
      <li
        class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <span class="cbs-tooltip"> Payments </span>
      </li>
    </router-link>

    <router-link to="/communications" data-te-toggle="tooltip">
      <li
        class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
          />
        </svg>
        <span class="cbs-tooltip"> Communications </span>
      </li>
    </router-link>

    <router-link to="/reports" data-te-toggle="tooltip">
      <li
        class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
          />
        </svg>
        <span class="cbs-tooltip"> Reports </span>
      </li>
    </router-link>

    <!-- <router-link to="/api-documentation" data-te-toggle="tooltip">
      <li
        class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
          />
        </svg>

        <span class="cbs-tooltip"> API Documentation </span>
      </li>
    </router-link> -->
    <template v-if="this.userInfo.user.role == 'admin'">
      <router-link to="/workspace" data-te-toggle="tooltip">
        <li
          class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480] hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
            />
          </svg>

          <span class="cbs-tooltip"> Workspace </span>
        </li>
      </router-link>
    </template>

    <router-link to="/settings" data-te-toggle="tooltip">
      <li
        class="hover-tooltip sticky-hover w-full h-[60px] p-5 border-b border-gray-600 flex items-center justify-center text-white hover:bg-[#002480]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        <span class="cbs-tooltip"> Settings </span>
      </li>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["userInfo", "isAuthenticated"]),
};
</script>
<style scoped>
.hover-tooltip {
  position: relative;
  /* border: 2px solid #000000;
	border-radius: 100px; */
}

.hover-tooltip .cbs-tooltip {
  background: #105c8e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 12px 15px;
  color: #fff;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: -10;
  font-weight: 600;
  letter-spacing: 0px;
  visibility: hidden;
  width: 170px;
}

.hover-tooltip .cbs-tooltip::before {
  content: "";
  position: absolute;
  left: -10px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  z-index: 9;
  width: 10px;
  border-left: 10px solid #105c8e;
  transform: rotate(180deg);
}

.sticky-hover .cbs-tooltip::hover {
  left: calc(100% + 10px);
  opacity: 1;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  visibility: visible;
}

.hover-tooltip:hover .cbs-tooltip {
  left: calc(100% + 10px);
  opacity: 1;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  visibility: visible;
}
</style>
