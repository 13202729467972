<template>
  <div class="w-full h-screen overflow-hidden">
    <!-- navbar -->
    <Header></Header>
    <!-- navbar -->

    <div class="flex">
      <!-- left navbar -->
      <LeftNav></LeftNav>
      <!-- left navbar -->
      <div class="flex-1 flex flex-col h-full p-2">
        <div class="flex gap-1">
          <button
            @click="active_tap = 'user'"
            class="px-3 py-1 rounded-md"
            :class="[
              active_tap == 'user' ? 'bg-[#105c8e] text-white' : 'border',
            ]"
          >
            User
          </button>
          <button
            class="px-3 py-1 rounded-md"
            :class="[
              active_tap == 'distributor'
                ? 'bg-[#105c8e] text-white'
                : 'border',
            ]"
            @click="active_tap = 'distributor'"
          >
            Agency
          </button>
          <button
            class="px-3 py-1 rounded-md"
            :class="[
              active_tap == 'agency' ? 'bg-[#105c8e] text-white' : 'border',
            ]"
            @click="active_tap = 'agency'"
          >
            Client
          </button>

          <button
            class="px-3 py-1 rounded-md"
            :class="[
              active_tap == 'sales_manager'
                ? 'bg-[#105c8e] text-white'
                : 'border',
            ]"
            @click="active_tap = 'sales_manager'"
          >
            Sales Manager
          </button>
          <button
            class="px-3 py-1 rounded-md"
            :class="[
              active_tap == 'sales_executive'
                ? 'bg-[#105c8e] text-white'
                : 'border',
            ]"
            @click="active_tap = 'sales_executive'"
          >
            Sales Executive
          </button>
        </div>
        <!-- User Details -->
        <div v-if="active_tap == 'user'" class="w-full px-0 py-0">
          <div class="flex items-center justify-between px-6 py-3 shadow-lg">
            <div class="pr-6">
              <h1 class="text-[#105c8e] text-xl font-bold">Manage Users</h1>
            </div>

            <div class="flex items-center gap-10">
              <div>
                <button
                  @click="is_popup = true"
                  class="flex items-center gap-2 min-w-[80px] px-6 py-1.5 justify-center text-sm font-normal bg-[#105c8e] text-white border-green-800 rounded shadow-md"
                >
                  <span class="">+</span>
                  <span class=""> Create </span>
                </button>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col gap-6 bg-[#f2f3f4] h-[calc(100vh-155px)] overflow-auto"
          >
            <div class="px-6">
              <div>
                <table class="bg-white w-full">
                  <thead class="bg-[#105c8e] border-b sticky top-0 text-white">
                    <tr>
                      <th class="text-base text-left font-medium px-4 py-2">
                        S.No
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Name
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Email ID
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Phone Number
                      </th>

                      <th class="text-base text-left font-medium px-4 py-2">
                        Roles
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Joined Date
                      </th>

                      <th class="text-base text-left font-medium px-4 py-2">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border-b"
                      v-for="(user, id) in user_details"
                      :key="id"
                    >
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ id + 1 }}
                      </td>

                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap flex gap-3"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'user']"
                          class="text-indigo-400"
                        />
                        {{ user.display_name }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'paper-plane']"
                          class="text-gray-400 px-2"
                        />
                        {{ user.email }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap flex gap-3"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'phone']"
                          class="text-green-500"
                        />
                        {{ user.phone_number }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ user.role }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ user.date_joined }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                        :class="[
                          user.is_active == true
                            ? 'text-green-400'
                            : 'text-red-400',
                        ]"
                      >
                        {{ user.is_active == true ? "Active" : "In-Active" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Distributor -->
        <div v-if="active_tap == 'distributor'" class="w-full px-0 py-0">
          <div class="flex items-center justify-between px-6 py-3 shadow-lg">
            <div class="pr-6">
              <h1 class="text-[#105c8e] text-xl font-bold">Manage Agency</h1>
            </div>

            <div class="flex items-center gap-10">
              <div class="flex">
                <button
                  @click="is_popup = true"
                  class="flex items-center gap-2 min-w-[80px] px-6 py-1.5 justify-center text-sm font-normal bg-[#105c8e] text-white border-green-800 rounded shadow-md"
                >
                  <span class="">+</span>
                  <span class=""> Assign </span>
                </button>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col gap-6 bg-[#f2f3f4] h-[calc(100vh-155px)] overflow-auto"
          >
            <div class="px-6">
              <div>
                <table class="bg-white w-full">
                  <thead class="bg-[#105c8e] border-b sticky top-0 text-white">
                    <tr>
                      <th class="text-base text-left font-medium px-4 py-2">
                        S.No
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Name
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Distributor Id
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(distributor, index) in this.distributor_list"
                      :key="distributor"
                      class="border-b"
                    >
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ index + 1 }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ distributor.name }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ distributor.distribution_id }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Agency -->
        <div v-if="active_tap == 'agency'" class="w-full px-0 py-0">
          <div class="flex items-center justify-between px-6 py-3 shadow-lg">
            <div class="pr-6">
              <h1 class="text-[#105c8e] text-xl font-bold">Manage Client</h1>
            </div>

            <div class="flex items-center gap-10">
              <div class="flex">
                <button
                  @click="is_popup = true"
                  class="flex items-center gap-2 min-w-[80px] px-6 py-1.5 justify-center text-sm font-normal bg-[#105c8e] text-white border-green-800 rounded shadow-md"
                >
                  <span class="">+</span>
                  <span class=""> Assign </span>
                </button>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col gap-6 bg-[#f2f3f4] h-[calc(100vh-155px)] overflow-auto"
          >
            <div class="px-6">
              <div>
                <table class="bg-white w-full">
                  <thead class="bg-[#105c8e] border-b sticky top-0 text-white">
                    <tr>
                      <th class="text-base text-left font-medium px-4 py-2">
                        S.No
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Client Name
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Agency Name
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Distribution Code
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border-b"
                      v-for="(agency, index) in this.agency_list"
                      :key="agency"
                    >
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ index + 1 }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ agency.name }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ agency.distributor.name }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ agency.distributor_code }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- sales Manager -->
        <div v-if="active_tap == 'sales_manager'" class="w-full px-0 py-0">
          <div class="flex items-center justify-between px-6 py-3 shadow-lg">
            <div class="pr-6">
              <h1 class="text-[#105c8e] text-xl font-bold">Sales Managers</h1>
            </div>

            <div class="flex items-center gap-10">
              <div class="flex">
                <button
                  @click="is_popup = true"
                  class="flex items-center gap-2 min-w-[80px] px-6 py-1.5 justify-center text-sm font-normal bg-[#105c8e] text-white border-green-800 rounded shadow-md"
                >
                  <span class="">+</span>
                  <span class=""> Assign </span>
                </button>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col gap-6 bg-[#f2f3f4] h-[calc(100vh-155px)] overflow-auto"
          >
            <div class="px-6">
              <div>
                <table class="bg-white w-full">
                  <thead class="bg-[#105c8e] border-b sticky top-0 text-white">
                    <tr>
                      <th class="text-base text-left font-medium px-4 py-2">
                        S.No
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Name
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Email
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Phone
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Agency
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(manager, index) in this.sales_manager_list"
                      :key="manager"
                      class="border-b"
                    >
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ index + 1 }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ manager.sales_manager.display_name }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ manager.sales_manager.email }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ manager.sales_manager.phone_number }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ manager.s_distributor_agency.name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- sales Executive -->
        <div v-if="active_tap == 'sales_executive'" class="w-full px-0 py-0">
          <div class="flex items-center justify-between px-6 py-3 shadow-lg">
            <div class="pr-6">
              <h1 class="text-[#105c8e] text-xl font-bold">Sales Executive</h1>
            </div>

            <div class="flex items-center gap-10">
              <div class="flex">
                <button
                  @click="is_popup = true"
                  class="flex items-center gap-2 min-w-[80px] px-6 py-1.5 justify-center text-sm font-normal bg-[#105c8e] text-white border-green-800 rounded shadow-md"
                >
                  <span class="">+</span>
                  <span class=""> Assign </span>
                </button>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col gap-6 bg-[#f2f3f4] h-[calc(100vh-155px)] overflow-auto"
          >
            <div class="px-6">
              <div>
                <table class="bg-white w-full">
                  <thead class="bg-[#105c8e] border-b sticky top-0 text-white">
                    <tr>
                      <th class="text-base text-left font-medium px-4 py-2">
                        S.No
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Name
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Email
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Sales Manager
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Agency
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(executive, index) in this.sales_executive_list"
                      :key="executive"
                      class="border-b"
                    >
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ index + 1 }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ executive.sales_executive.display_name }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ executive.sales_executive.email }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ executive.sales_manager.sales_manager.display_name }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ executive.s_distributor_agency.name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="is_popup"
    class="w-full min-h-screen fixed top-0 left-0 bg-black bg-opacity-90 h-full overflow-y-auto flex items-center justify-center text-white"
  >
    <!-- User -->
    <div
      v-if="this.active_tap == 'user'"
      class="h-auto w-[80%] max-w-3xl bg-gray-800 rounded-md flex gap-1 p-8 overflow-y-auto items-start relative"
    >
      <button
        @click="is_popup = false"
        class="py-1 absolute top-1 right-2 text-sm bg-red-700 px-4 rounded-md"
      >
        Close
      </button>
      <div class="w-full">
        <div class="">
          <!-- distributor -->
          <div
            v-if="this.active_tap == 'user'"
            class="h-auto w-[80%] max-w-3xl bg-gray-800 rounded-md flex gap-1 p-8 overflow-y-auto items-start relative"
          >
            <div class="w-full">
              <h1>Add User</h1>
              <div class="text-start">
                <form
                  ref="user_post"
                  @submit.prevent="newUserCreate"
                  class="text-start flex flex-col gap-4"
                >
                  <div class="w-full flex flex-col gap-2 justify-start">
                    <label for="user_name">Display Name</label>
                    <input
                      type="text"
                      name=""
                      id="user_name"
                      class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                      required
                    />
                  </div>
                  <div class="w-full flex flex-col gap-2 justify-start">
                    <label for="user_email">Email</label>
                    <input
                      type="email"
                      name=""
                      id="user_email"
                      required
                      class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                    />
                  </div>

                  <div class="w-full flex flex-col gap-2 justify-start">
                    <label for="user_phone">Phone</label>
                    <input
                      type="phone"
                      name=""
                      id="user_phone"
                      required
                      class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                    />
                  </div>

                  <div class="w-full flex flex-col gap-2 justify-start">
                    <label for="user_roles">Role</label>
                    <select
                      name=""
                      id="user_roles"
                      required
                      class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                    >
                      <option value="admin">Admin</option>
                      <option value="Sales Manager">Sales Manager</option>
                      <option value="Sales Executive">Sales Executive</option>
                      <option value="Project Manager">Project Manager</option>
                      <option value="Team Member">Team Member</option>
                    </select>
                  </div>
                  <div class="text-center pt-4">
                    <button
                      type="submit"
                      class="px-4 py-1 bg-gray-700 rounded-md"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- distributor -->
    <div
      v-if="this.active_tap == 'distributor'"
      class="h-auto w-[80%] max-w-3xl bg-gray-800 rounded-md flex gap-1 p-8 overflow-y-auto items-start relative"
    >
      <button
        @click="is_popup = false"
        class="py-1 absolute top-1 right-2 text-sm bg-red-700 px-4 rounded-md"
      >
        Close
      </button>
      <div class="w-full">
        <h1>Add Distributor</h1>
        <div class="text-start">
          <form
            ref="distributor_post"
            @submit.prevent="distributorCreate"
            class="flex flex-col gap-4"
          >
            <div class="flex flex-col gap-2">
              <label for="distributor_name">Distributor Name</label>
              <input
                type="text"
                name=""
                class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                id="distributor_name"
                required
              />
            </div>
            <div class="flex flex-col gap-2">
              <label for="distributor_id"
                >Distribution ID <span class="text-red-400">*</span></label
              >
              <input
                type="text"
                name=""
                id="distributor_id"
                class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                required
              />
            </div>

            <div class="text-center pt-4">
              <button type="submit" class="px-4 py-1 bg-gray-700 rounded-md">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- agency -->
    <div
      v-if="this.active_tap == 'agency'"
      class="h-auto w-[80%] max-w-5xl bg-gray-800 rounded-md flex gap-1 p-8 overflow-y-auto items-start relative"
    >
      <button
        @click="is_popup = false"
        class="py-1 absolute top-1 right-2 text-sm bg-red-700 px-4 rounded-md"
      >
        Close
      </button>
      <div class="w-full">
        <h1>Add Agency</h1>
        <div class="text-start">
          <form
            ref="agency_post"
            @submit.prevent="agencyCreate"
            class="flex flex-col gap-4"
          >
            <div class="flex flex-col gap-2">
              <label for="agency_name">Agency Name</label>
              <input
                type="text"
                name=""
                class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                id="agency_name"
                required
              />
            </div>
            <div class="flex flex-col gap-2">
              <label for="distributor_id"
                >Distribution ID <span class="text-red-400">*</span></label
              >
              <select
                name=""
                id="distributor_id"
                class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                required
              >
                <option value="" selected disabled>Select Distributor</option>
                <option
                  :value="distributor.id"
                  v-for="distributor in distributor_list"
                  :key="distributor"
                >
                  {{ distributor.name }}
                </option>
              </select>
            </div>

            <div class="text-center pt-4">
              <button type="submit" class="px-4 py-1 bg-gray-700 rounded-md">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Sales manager -->
    <div
      v-if="this.active_tap == 'sales_manager'"
      class="h-auto w-[80%] max-w-5xl bg-gray-800 rounded-md flex gap-1 p-8 overflow-y-auto items-start relative"
    >
      <button
        @click="is_popup = false"
        class="py-1 absolute top-1 right-2 text-sm bg-red-700 px-4 rounded-md"
      >
        Close
      </button>
      <div class="w-full">
        <h1>Add Sales Manager</h1>
        <div class="text-start">
          <form
            ref="sales_manager_post"
            @submit.prevent="salesManagerCreate"
            class="flex flex-col gap-4"
          >
            <div class="flex flex-col gap-2">
              <label for="sales_manager_id">Sales Manager</label>
              <select
                name=""
                id="sales_manager_id"
                class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                required
              >
                <option value="" selected disabled>Select Sales Manager</option>
                <template v-for="user in user_details" :key="user">
                  <option :value="user.id" v-if="user.role == 'Sales Manager'">
                    {{ user.email }}
                  </option>
                </template>
              </select>
            </div>
            <div class="flex flex-col gap-2">
              <label for="">Agency </label>
              <select
                name=""
                id="agency_id"
                class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                required
              >
                <option value="" selected disabled>Select The Agency</option>
                <option
                  :value="agency.id"
                  v-for="agency in distributor_list"
                  :key="agency"
                >
                  {{ agency.name }}
                </option>
              </select>
            </div>

            <div class="text-center pt-4">
              <button type="submit" class="px-4 py-1 bg-gray-700 rounded-md">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Sales Executive -->
    <div
      v-if="this.active_tap == 'sales_executive'"
      class="h-auto w-[80%] max-w-5xl bg-gray-800 rounded-md flex gap-1 p-8 overflow-y-auto items-start relative"
    >
      <button
        @click="is_popup = false"
        class="py-1 absolute top-1 right-2 text-sm bg-red-700 px-4 rounded-md"
      >
        Close
      </button>
      <div class="w-full">
        <h1>Add Sales Executive</h1>
        <div class="text-start">
          <form
            ref="sales_executive_post"
            @submit.prevent="salesExecutiveCreate"
            class="flex flex-col gap-4"
          >
            <div class="flex flex-col gap-2">
              <label for="sales_executive_id">Sales Executive</label>
              <select
                name=""
                id="sales_executive_id"
                class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                required
              >
                <option value="" selected disabled>
                  Select Sales Executive
                </option>
                <template v-for="user in user_details" :key="user">
                  <option
                    :value="user.id"
                    v-if="user.role == 'Sales Executive'"
                  >
                    {{ user.email }}
                  </option>
                </template>
              </select>
            </div>
            <div class="flex flex-col gap-2">
              <label for="sales_manager_id">Sales Manager</label>
              <select
                name=""
                id="sales_manager_id"
                class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                required
              >
                <option value="" selected disabled>Select Sales Manager</option>
                <template
                  v-for="sales_manager in sales_manager_list"
                  :key="sales_manager"
                >
                  <option :value="sales_manager.id">
                    {{ sales_manager.sales_manager.display_name }}
                  </option>
                </template>
              </select>
            </div>
            <div class="flex flex-col gap-2">
              <label for="agency_id"
                >Agency <span class="text-red-400">*</span></label
              >
              <select
                name=""
                id="agency_id"
                class="rounded-md text-black text-sm px-2 font-normal w-full h-9"
                required
              >
                <option value="" selected disabled>Select The Agency</option>
                <option
                  :value="agency.id"
                  v-for="agency in distributor_list"
                  :key="agency"
                >
                  {{ agency.name }}
                </option>
              </select>
            </div>
            <div class="flex gap-2 items-center">
              <input type="checkbox" name="" id="executive_status" />
              <label for="executive_status" class="pt-2">Active Status </label>
            </div>

            <div class="text-center pt-4">
              <button class="px-4 py-1 bg-gray-700 rounded-md">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="this.message.text != ''"
    class="fixed top-16 right-6 min-w-[420px] min-h-[60px] rounded-md flex items-center justify-center text-white font-semibold z-40"
    :class="[message.status == 200 ? 'bg-green-400' : 'bg-red-400']"
  >
    <p>
      {{ message.text }}
    </p>
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import LeftNav from "@/components/LeftNav.vue";
import { Modal, Ripple, initTE } from "tw-elements";
import { mapGetters } from "vuex";

export default {
  name: "UserManagement",
  data() {
    return {
      user_details: [],
      is_popup: false,
      active_tap: "user",
      dataSelect: true,
      distributor_list: [],
      agency_list: [],
      sales_manager_list: [],
      sales_executive_list: [],
      message: {
        status: 200,
        text: "",
      },
    };
  },
  components: { LeftNav, Header },
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  created() {
    this.userCall();
    this.distributorCall();
    this.agencyCall();
    this.salesManagerCall();
    this.salesExecutiveCall();
  },

  mounted() {
    initTE({ Modal, Ripple });
  },
  methods: {
    userCall() {
      axios
        .get(
          `${process.env.VUE_APP_API}/user/all/detail/?Key=${this.userInfo.token}`
        )
        .then((response) => {
          console.log(response.data);
          this.user_details = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    distributorCall() {
      axios
        .get(
          `${process.env.VUE_APP_API}/distributor/details/?Key=${this.userInfo.token}`
        )
        .then((response) => {
          console.log(response.data);
          this.distributor_list = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    agencyCall() {
      axios
        .get(
          `${process.env.VUE_APP_API}/agency/details/?Key=${this.userInfo.token}`
        )
        .then((response) => {
          console.log(response.data);
          this.agency_list = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salesManagerCall() {
      axios
        .get(
          `${process.env.VUE_APP_API}/sales-manager/details/?Key=${this.userInfo.token}`
        )
        .then((response) => {
          console.log(response.data);
          this.sales_manager_list = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salesExecutiveCall() {
      axios
        .get(
          `${process.env.VUE_APP_API}/sales-executive/details/?Key=${this.userInfo.token}`
        )
        .then((response) => {
          console.log(response.data);
          this.sales_executive_list = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newUserCreate(el) {
      let dataJson = {
        display_name: el.target.elements.user_name.value,
        email: el.target.elements.user_email.value,
        phone_number: el.target.elements.user_phone.value,
        is_active: true,
        role: el.target.elements.user_roles.value,
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/user/add/?Key=${this.userInfo.token}`,
          dataJson
        )
        .then((response) => {
          if (response.data.status == 200) {
            console.log(response.data, "response.data", response.data.status);
            this.$refs.user_post.reset();
            this.is_popup = false;
            this.message.text = "User Added";
            this.message.status = 200;
            this.userCall();
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.text = "Error";
          this.message.status = 400;
        });
      setTimeout(() => {
        this.message.text = "";
        this.message.status = 200;
      }, 2000);
    },
    distributorCreate(el) {
      let dataJson = {
        name: el.target.elements.distributor_name.value,
        distribution_id: el.target.elements.distributor_id.value,
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/distributor/add/?Key=${this.userInfo.token}`,
          dataJson
        )
        .then((response) => {
          if (response.data.status == 200) {
            console.log(response.data);
            this.$refs.distributor_post.reset();
            this.is_popup = false;
            this.message.text = "Distributor Added";
            this.message.status = 200;
            this.distributorCall();
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.text = "Error";
          this.message.status = 400;
        });
      setTimeout(() => {
        this.message.text = "";
        this.message.status = 200;
      }, 2000);
    },
    agencyCreate(el) {
      let dataJson = {
        name: el.target.elements.agency_name.value,
        distributor: el.target.elements.distributor_id.value,
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/agency/add/?Key=${this.userInfo.token}`,
          dataJson
        )
        .then((response) => {
          if (response.data.status == 200) {
            console.log(response.data);
            this.$refs.agency_post.reset();
            this.is_popup = false;
            this.message.text = "Agency Added";
            this.message.status = 200;
            this.agencyCall();
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.text = "Error";
          this.message.status = 400;
        });
      setTimeout(() => {
        this.message.text = "";
        this.message.status = 200;
      }, 2000);
    },
    salesManagerCreate(el) {
      let dataJson = {
        sales_manager: el.target.elements.sales_manager_id.value,
        s_distributor_agency: el.target.elements.agency_id.value,
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/sales-manager/add/?Key=${this.userInfo.token}`,
          dataJson
        )
        .then((response) => {
          if (response.data.status == 200) {
            console.log(response.data);
            this.$refs.sales_manager_post.reset();
            this.is_popup = false;
            this.message.text = "Sales Manager Added";
            this.message.status = 200;
            this.salesManagerCall();
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.text = "Error";
          this.message.status = 400;
        });
      setTimeout(() => {
        this.message.text = "";
        this.message.status = 200;
      }, 2000);
    },
    salesExecutiveCreate(el) {
      let dataJson = {
        sales_executive: el.target.elements.sales_executive_id.value,
        sales_manager: el.target.elements.sales_manager_id.value,
        s_distributor_agency: el.target.elements.agency_id.value,
        is_active: el.target.elements.executive_status.value,
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/sales-executive/add/?Key=${this.userInfo.token}`,
          dataJson
        )
        .then((response) => {
          if (response.data.status == 200) {
            console.log(response.data);
            this.$refs.sales_executive_post.reset();
            this.is_popup = false;
            this.message.text = "Sales Executive Added";
            this.message.status = 200;
            this.salesExecutiveCall();
          }
        })
        .catch((error) => {
          console.log(error);
          this.message.text = "Error";
          this.message.status = 400;
        });
      setTimeout(() => {
        this.message.text = "";
        this.message.status = 200;
      }, 2000);
    },
    activeCheck(el) {
      let active = el.target.value;
      console.log(active);
    },
    selectCheckbox(event) {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.dataSelect = true;
      } else {
        this.dataSelect = true;
      }
    },
  },
};
</script>

<style scoped>
label::after {
  content: "*";
  color: red;
}
</style>
