<template>
  <div>
    <div
      class="w-full h-[60px] bg-[#105c8e] shadow-md flex items-center justify-between"
    >
      <div class="flex items-center gap-5">
        <div class="px-1.5 py-3 flex gap-2 items-center justify-start">
          <div
            name=""
            id=""
            class="bg-transparent text-white text-xl text-start"
          >
            <img
              src="@/assets/new-sriher-logo.png"
              alt=""
              class="w-60 object-contain bg-white p-2 rounded-md"
            />
          </div>
          <!-- <P class="text-white font-bold">SRI RAMACHANDRA</P> -->
        </div>
      </div>

      <div class="flex items-center gap-5">
        <div v-if="isAuthenticated" class="relative pr-5" data-te-dropdown-ref>
          <div
            class="flex items-end gap-2 font-bold text-lg cursor-pointer"
            id="dropdownMenuButton2"
            data-te-dropdown-toggle-ref
            aria-expanded="false"
          >
            <button class="bg-white rounded-full h-[30px] w-[30px] pt-0.5">
              {{ this.userInfo.user.display_name.slice(0, 1) }}
            </button>
            <p class="text-white pt-1">
              {{ this.userInfo.user.display_name }}
            </p>
          </div>
          <ul
            class="absolute z-[1000] float-left m-0 mt-10 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
            aria-labelledby="dropdownMenuButton2"
            data-te-dropdown-menu-ref
          >
            <li>
              <router-link
                class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                to="/settings"
                data-te-dropdown-item-ref
                >Settings</router-link
              >
            </li>
            <li
              @click="logOut"
              class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
              data-te-dropdown-item-ref
            >
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Dropdown, Ripple, initTE } from "tw-elements";

export default {
  name: "NavigationView",
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  created() {
    console.log(this.userInfo.user.display_name);
  },
  mounted() {
    initTE({ Dropdown, Ripple });
  },
  methods: {
    ...mapActions(["logOutUser"]),
    logOut() {
      this.logOutUser();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
